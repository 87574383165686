import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import useDebounce from '~/hooks/useDebounce';
import apiAxios from '~/services/api';
import { Lista } from './Lista';
import { InputAsyncSelectProps } from './protocols';
import { InputContainer } from './styles';

export const InputAsyncSelect: React.FC<InputAsyncSelectProps> = ({
  label = '',
  placeholder = '',
  listWidth = '100%',
  name,
  register,
  isError,
  maxLength,
  control,
  changeSelected,
  api,
  typeError,
  includeNoneOption = false,
  ...rest
}) => {
  /**
   * debouncedFn
   * Hook de Debounce usado para efetuar a busca após digitação do usuário
   */
  const { debouncedFn } = useDebounce();
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef: any = useRef(null);
  /**
   * selectedRef
   * Opção selecionada da lista
   */
  const selectedRef: any = useRef({
    label: undefined,
    value: undefined,
  });

  const [selected, setSelected] = useState<any>({
    label: undefined,
    value: undefined,
  });
  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');
  /**
   * limit
   * Quantidade de registros buscados por vez na api
   */
  const limit = 10;

  /**
   * fetchData
   * Método que se comunica com a api
   */

  const fetchData = async ({
    pageParam = 1,
    url,
  }: {
    pageParam: number;
    url: InputAsyncSelectProps['api'];
  }): Promise<any> => {
    if (filter === '' && !url?.searchBeforeFilter) {
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }
    if (url.dependsOf && url.dependsOf.length > 0) {
      for (let i = 0; i < url.dependsOf.length; i++) {
        if (url.dependsOf[i] === 'routeParam' && url.routeParam === undefined) {
          return {
            success: false,
            count: 0,
            data: [],
            fields: [],
            message: 'dependencies have not been met.',
            pagination: {
              lastPage: 2917,
              nextPage: 2,
              prevPage: 1,
              startPage: 1,
            },
          };
        }
        if (
          url.bodyParams &&
          url.bodyParams[url.dependsOf[i]] === undefined &&
          url.dependsOf[i] !== 'routeParam'
        ) {
          return {
            success: false,
            count: 0,
            data: [],
            fields: [],
            message: 'dependencies have not been met.',
            pagination: {
              lastPage: 2917,
              nextPage: 2,
              prevPage: 1,
              startPage: 1,
            },
          };
        }
      }
    }
    if (api.searchBeforeFilter === false && filter.length < 3) {
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }
    const route = url.routeParam ? `${url.route}/${url.routeParam}` : url.route;
    try {
      const { data: dataFields } = await apiAxios[api.method](route, {
        page: pageParam,
        limit,
        filter,
        ...(url.bodyParams && url.bodyParams),
        // key params é utilizada para caso a requisição seja do tipo get
        params: {
          page: pageParam,
          limit,
          filter,
          ...(url.bodyParams && url.bodyParams),
        },
      });
      let options = dataFields.data.map((dt: any) => {
        let objLabel = '';
        const value = url.fildDescriptionForValue
          ? dt[url.fildDescriptionForValue]
          : dt[url.fields[0]];
        const { fields } = url;
        if (url.fildDescriptionForLabel) {
          objLabel = dt[url.fildDescriptionForLabel];
        } else {
          switch (fields.length) {
            case 1:
              objLabel = dt[fields[0]];
              break;
            case 2:
              if (api.parenthesesAroundLastLabel) {
                objLabel = `${dt[fields[0]]} - (${dt[fields[1]]})`;
              } else {
                objLabel = `${dt[fields[0]]} - ${dt[fields[1]]}`;
              }
              break;
            default:
              objLabel = fields
                .map((field, index, array) => {
                  const isLast = index === array.length - 1;
                  const isSecondLast = index === array.length - 2;

                  if (isLast) {
                    const previousField = array[index - 1];
                    const previousValue = dt[previousField];
                    if (previousValue && previousValue.startsWith('(')) {
                      return `${dt[field]} `;
                    }
                    return `(${dt[field]}) `;
                  }
                  if (isSecondLast) {
                    return `${dt[field]} `;
                  }
                  return `${dt[field]} - `;
                })
                .join('');

              break;
          }
        }

        return {
          ...dt, // traz o restante dos dados do select
          value,
          /**
           * O parametro label será definido de acordo com a quantidade de colunas passadas na propriedade api.fields
           * Ex: fields: ['cod_condicao', 'des_definicao', 'des_condicao'] => [1, 'DD', 'DIAS DA DATA']
           *    A label será criada com todos os campos passados a partir da posição 1,
           *    ignorando a posição 0 (espera-se que a posição zero seja uma coluna relacionada ao código, não descritiva), logo o retorno seria 'DD - DIAS DA DATA'
           * Caso sejam passados apenas 2 campos a label será definida com ambos
           * Ex: fields: ['cod_condicao', 'des_condicao'] => [1, 'DIAS DA DATA']
           *    O retorno seria '1 - DIAS DA DATA'
           */
          label: url.fildDescriptionForLabel || objLabel,
        };
      });
      if (includeNoneOption && filter === '' && pageParam === 1) {
        options = [
          {
            value: -1,
            label: 'NENHUM(A)',
          },
          ...options,
        ];
      }
      return {
        ...dataFields,
        data: options,
      };
    } catch (err: any) {
      return {
        success: false,
        count: 0,
        data: [],
        fields: [],
        message: 'url not found',
        pagination: {
          lastPage: 2917,
          nextPage: 2,
          prevPage: 1,
          startPage: 1,
        },
      };
    }
  };

  /**
   * useInfiniteQuery
   * Hook do react-query para listagem infinita de dados
   */
  const queryClient = useQueryClient();
  const { data, hasNextPage, fetchNextPage, isFetching, refetch } =
    useInfiniteQuery(
      [`input_select_${name}`, { url: api }],
      ({ pageParam = 1 }) => fetchData({ pageParam, url: api }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const maxPages = lastPage.pagination.lastPage + 1;
          const nextPage = allPages.length + 1;
          return nextPage <= maxPages ? nextPage : undefined;
        },
        refetchOnWindowFocus: false,
      },
    );

  useEffect(() => {
    if (!isFetching) {
      setIsOpen(true);
    }
  }, [filter]);
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>

          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { value } }) => {
              if (inputRef?.current) {
                if (value && value !== '') {
                  inputRef.current.placeholder = value.label;
                  selectedRef.current = value;
                } else {
                  inputRef.current.placeholder = placeholder;
                  selectedRef.current = '';
                }
              }
              return (
                <>
                  <label className="select-container">
                    <input
                      {...register(name)}
                      type="text"
                      maxLength={maxLength}
                      className={
                        isError ? 'form-control is-invalid' : 'form-control'
                      }
                      title={typeError || undefined}
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        event.target.value = event.target.value.toUpperCase();
                        setFilter(event.target.value.toUpperCase());
                        debouncedFn(() => refetch(), 500);
                      }}
                      autoComplete="off"
                      onClick={(event: any) => {
                        if (selectedRef.label) {
                          event.target.placeholder = selectedRef.label;
                        }
                        event.target.value = '';
                        setIsOpen(true);
                      }}
                      onChangeCapture={(e: any) => {
                        if (e.target.value.length > 0) {
                          setIsOpen(true);
                        }
                      }}
                      ref={inputRef}
                      {...rest}
                    />
                    <div className="drop-indicator">
                      <span role="img" aria-label="open">
                        {filter && isFetching ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="spinner"
                          />
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            role="presentation"
                          >
                            <path
                              d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                              fill="currentColor"
                              fillRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                    </div>
                  </label>
                  {isOpen && (
                    <Lista
                      inputRef={inputRef}
                      listWidth={listWidth}
                      setIsOpen={setIsOpen}
                      setFilter={setFilter}
                      hasNextPage={hasNextPage}
                      fetchNextPage={fetchNextPage}
                      data={data}
                      isFetching={isFetching}
                      changeSelected={changeSelected}
                      messageForDependsOf={api.messageForDependsOf}
                      name={name}
                      queryName={name}
                      queryClient={queryClient}
                      selectedRef={selectedRef}
                      useNanoIdForKey={api.useNanoIdForKey}
                    />
                  )}
                </>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
